/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer";
// import "./layout.css"
import * as CONST from "../CONSTANTS";
import SubmitContext from "../context/submitContext";
import { JornayaHiddenInput } from "./thirdParty/jornaya";
import { MediaalphaPlaceholder } from "./thirdParty/mediaalpha";

function handleSubmit(event) {
  event.preventDefault();
}

const Layout = ({ children, layoutType }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  if (layoutType === CONST.LAYOUT_HOME_PAGE) {
    return (
      <>
        <Header />
        <div className="content-wrap home">{children}</div>
        <Footer />
      </>
    );
  }

  if (layoutType === CONST.LAYOUT_ORDINARY_PAGE) {
    return (
      <>
        <Header />
        {children}
        <Footer />
      </>
    );
  }

  if (layoutType === CONST.LAYOUT_TCPA_PAGE) {
    return <>
      <Header includeC2C={false} />
      {children}
      <Footer />
    </>;
  }

  if (layoutType === CONST.LAYOUT_WIZARD_PAGE) {
    return (
      <>
        <Header />
        <SubmitContext.Consumer>
          {submitContext => (
            <div
              className={`content-wrap wizard multistep  ${
                submitContext.leadSent ? "lead-sent" : ""
              }`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 form-col">
                    <div className="form-box">
                      <form onSubmit={handleSubmit}>
                        {children}
                        <JornayaHiddenInput />
                      </form>
                    </div>
                  </div>
                  <div className="col-12 thankyou-placement-wrap">
                    <MediaalphaPlaceholder id="thankyou" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </SubmitContext.Consumer>
        <Footer />
      </>
    );
  }

  if (layoutType === CONST.LAYOUT_ONLY_HEADER) {
    return (
      <>
        <Header />
        {children}
      </>
    );
  }

  if (layoutType === CONST.LAYOUT_TCPA_PAGE) {
    return <>{children}</>;
  }

  //default
  if (!layoutType) {
    return (
      <>
        <Header />
        <div className="container text-center">{children}</div>
        <Footer />
      </>
    );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default Layout;
